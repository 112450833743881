<template>
  <div class="nearshore-team">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('/img/nearshore/header-nearshoring.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>Extend your technical or support team</h1>
        <p>
          Comstream’s nearshore capability gives you access to a highly competent talent pool, while
          keeping them aligned to your operations in a convenient, effective and efficient way.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="solutions">
        <h3>A solution for all your needs</h3>
        <div class="d-flex justify-content-between align-items-center">
          <div class="content">
            <p>
              After getting to know your needs, we’ll initiate a recruiting process where you select
              the most suitable team members for your dedicated nearshore team. Once in place, we
              make sure that they smoothly integrate with your working processes and that everything
              is working in the best way for you.
            </p>
            <p>
              Whenever you have a need for additional competence within certain areas, our inhouse
              recruiters are happy to help out. With our bundled recruiting and management services,
              we simply combines the best from two worlds.
            </p>
          </div>
          <div class="content">
            <img src="/img/nearshore/team-extension.png" alt="extend your team" />
          </div>
        </div>
      </div>
      <div class="small-boxes d-flex justify-content-between">
        <div class="box">
          <img src="/img/icons/icons8-target-100.png" alt="best job" />
          <h3>The best people for the job</h3>
          <p>
            After getting to know your requirements, we’ll put together and manage the candidates
            who have passed our discerning recruitment process.
          </p>
        </div>

        <div class="box">
          <img src="/img/icons/icons8-toolbox-100.png" alt="wide range of skills" />
          <h3>A wide range of skills</h3>
          <p>
            By working with us you’ll get the resource you need - from software development, QA and
            UX design through to support, technical operations and management.
          </p>
        </div>

        <div class="box">
          <img src="/img/icons/icons8-web-help-100.png" alt="all the way" />
          <h3>With you all the way</h3>
          <p>
            Whether you’re at the start of your project or it’s already underway, your nearshore
            team will ensure accelerated completion and quality delivery.
          </p>
        </div>

        <div class="box">
          <img src="/img/icons/icons8-internet-100.png" alt="on-site services" />
          <h3>On-site services</h3>
          <p>
            If there is a need for longer on-site presence at your office, we can easily facilitate
            this for all customers based within the EU.
          </p>
        </div>
      </div>
      <div class="solutions second">
        <div class="d-flex justify-content-between align-items-center">
          <div class="content">
            <img
              src="/img/nearshore/nico-alexander-VaP_l-v0mNs-unsplash.jpg"
              alt="business center"
            />
          </div>
          <div class="content">
            <h3>Why Bulgaria is ideal for your nearshoring needs</h3>
            <p>
              Bulgaria is well known as one of Europe’s leading IT hubs, which is why we have our
              technical site located in its dynamic capital: Sofia. As a member of the EU, it has an
              excellent reputation in the worlds of science and education and has been ranked third
              in the ‘Global IT IQ Report’, in terms of certified IT professionals per capita.
            </p>
            <p>
              Leading companies from around the world are now recognizing the wide range of highly
              qualified and experienced IT talent available, with a high level of English and
              international attitude. Why not join them and take advantage of the high-quality
              output and competitive cost level?
            </p>
          </div>
        </div>
      </div>
      <div class="call-to-action-boxes d-flex justify-content-between">
        <div
          class="box"
          style="
            background: url('/img/nearshore/nearshorе-development-bg.jpg');
            background-size: cover;
          "
        >
          <div class="content">
            <h3>Nearshore development</h3>
            <p>
              Tailor your ideal technical development team and enjoy flexible scalability to suit
              your exact needs.
            </p>
            <router-link
              to="/services/dedicated-nearshore-team/nearshore-development"
              class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="
            background: url('/img/nearshore/NearshoreTechnicalSupport-bg.jpg');
            background-size: cover;
          "
        >
          <div class="content">
            <h3>Outsourced support</h3>
            <p>
              Let us build, manage and equip your ideal technical support team, available to you and
              your end-users, 24/7 if needed.
            </p>
            <router-link to="/services/outsourced-support" class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Build Your Distributed Technical Team | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.nearshore-team {
  .solutions {
    padding: 70px 0;
    h3 {
      font-size: 38px;
      font-weight: 700;
      margin: 0 0 40px 0;
      text-align: center;
    }
    .content {
      h3 {
        text-align: left;
      }
      &:first-of-type {
        width: 55%;
      }
      &:nth-of-type(2) {
        flex: 1;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 3px;
      }
    }
    &.second {
      .content {
        &:first-of-type {
          flex: 1;
          margin-right: 50px;
        }
        &:nth-of-type(2) {
          width: 55%;
        }
      }
    }
  }
  .small-boxes {
    .box {
      width: 20%;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        display: block;
        margin: 12px auto;
      }
      h3 {
        font-size: 28px;
        font-weight: 500;
        height: 75px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .nearshore-team {
    .solutions {
      h3 {
        font-size: 2rem;
      }
      .d-flex {
        display: block !important;
        .content {
          width: 100%;
        }
      }
      &.second {
        .content {
          width: 100%;
          &:nth-of-type(2) {
            width: 100%;
            h3 {
              margin: 20px 0 12px 0;
            }
          }
        }
      }
    }
    .small-boxes {
      display: block !important;
      .box {
        width: 100%;
        h3 {
          height: auto;
        }
      }
    }
  }
}
</style>
